import React from "react";

//import stock
import stock from "../img/image1.jpg";
import stock1 from "../img/image2.jpg";
import stock2 from "../img/image3.jpg";
import stock3 from "../img/image4.jpg";
import stock4 from "../img/image5.jpg";
import stock5 from "../img/image6.jpg";
import nanhua1 from "../img/nanhua/image1.jpeg";
import nanhuatn from "../img/nanhua/thumbnail.jpeg";
import nanhua2 from "../img/nanhua/image2.jpg";
import nanhua3 from "../img/nanhua/image3.jpeg";
import nanhua4 from "../img/nanhua/image4.jpeg";
import nanhua5 from "../img/nanhua/image5.jpg";
import yusof1 from "../img/yusof/image1.jpeg";
import yusoftn from "../img/yusof/thumbnail.jpeg";
import yusof2 from "../img/yusof/image2.jpeg";
import yusof3 from "../img/yusof/image3.jpeg";
import yusof4 from "../img/yusof/image4.jpeg";
import yusof5 from "../img/yusof/image5.jpeg";
import mayflower1 from "../img/mayflower/image1.jpg";
import mayflowertn from "../img/mayflower/thumbnail.jpeg";
import mayflower2 from "../img/mayflower/image2.jpg";
import mayflower3 from "../img/mayflower/image3.jpg";
import mayflower4 from "../img/mayflower/image4.jpg";
import mayflower5 from "../img/mayflower/image5.jpg";

class Portfolio extends React.Component {
  render() {
    return (
      <section id="work" className="portfolio-mf sect-pt4 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="title-box text-center">
                <h3 className="title-a">Projects</h3>
                <p className="subtitle-a">
                  Here are some of our completed projects. Do contact us if you want to see more!
                </p>
                <div className="line-mf"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="work-box">
                <a href={nanhua1} data-lightbox="gallery-vmarine">
                  <div className="work-img">
                    <img src={nanhuatn} alt="" className="img-fluid" max-height="200px"/>
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-8">
                        <h2 className="w-title">Nan Hua Primary School</h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                            
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="w-like">
                          <span className="ion-ios-plus-outline"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                
                <a
                  href={nanhua2}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={nanhua3}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={nanhua4}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={nanhua5}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="work-box">
                <a href={yusof1} data-lightbox="gallery-aguadeluz">
                  <div className="work-img">
                    <img src={yusoftn} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-8">
                        <h2 className="w-title">Yusof Ishak Secondary School</h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                            
                          </span>{" "}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="w-like">
                          <span className="ion-ios-plus-outline"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <a
                  href={yusof2}
                  data-lightbox="gallery-aguadeluz"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={yusof3}
                  data-lightbox="gallery-aguadeluz"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={yusof4}
                  data-lightbox="gallery-aguadeluz"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={yusof5}
                  data-lightbox="gallery-aguadeluz"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="work-box">
                <a href={mayflower1} data-lightbox="gallery-todo">
                  <div className="work-img">
                    <img src={mayflowertn} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-8">
                        <h2 className="w-title">Mayflower Primary School</h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                          </span>
                          {/*} / <span className="w-date">18 Sep. 2018</span>*/}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="w-like">
                          <span className="ion-ios-plus-outline"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
                <a
                  href={mayflower2}
                  data-lightbox="gallery-todo"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={mayflower3}
                  data-lightbox="gallery-todo"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={mayflower4}
                  data-lightbox="gallery-todo"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={mayflower5}
                  data-lightbox="gallery-todo"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
              </div>
            </div>

          </div>
        </div>
      </section>
    );
  }
}

export default Portfolio;
