import React from "react";
// import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";


//import stock
import stock from "../img/image1.jpg";
import stock1 from "../img/image2.jpg";
import stock2 from "../img/image3.jpg";
import stock3 from "../img/image4.jpg";
import stock4 from "../img/image5.jpg";
import stock5 from "../img/image6.jpg";
import panel1 from "../img/services/panel/panel.JPG";
import panel2 from "../img/services/panel/panel2.jpg";
import panel3 from "../img/services/panel/panel3.jpeg";
import panel4 from "../img/services/panel/Panel4.jpeg";
import panel5 from "../img/services/panel/Panel5.jpeg";
import tile1 from "../img/services/Tiling/Tile.jpg";
import tile2 from "../img/services/Tiling/Tile2.jpg";
import tile3 from "../img/services/Tiling/Tile3.jpeg";
import tile4 from "../img/services/Tiling/Tile4.jpeg";
import tile5 from "../img/services/Tiling/Tile5.jpeg";
import pebble1 from "../img/services/PebbleWash/Pebble.jpeg";
import pebble2 from "../img/services/PebbleWash/Pebble2.jpeg";
import pebble3 from "../img/services/PebbleWash/Pebble3.jpeg";
import pebble4 from "../img/services/PebbleWash/Pebble4.jpeg";
import skim1 from "../img/services/Skimcoat/Skimcoat.jpeg";
import skim2 from "../img/services/Skimcoat/Skimcoat2.jpeg";
import skim3 from "../img/services/Skimcoat/Skimcoat3.jpeg";

import plaster1 from "../img/services/Plastering/Plaster.jpeg";
import plaster2 from "../img/services/Plastering/Plaster2.jpeg";
import skirting1 from "../img/services/Skirting/Skirting.jpeg";

class Services extends React.Component {
  render() {
    return (
      
      <section id="services" className="portfolio-mf sect-pt4 route">
        <div className="slide-container">
      
    </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="title-box text-center">
                <h3 className="title-a">Services</h3>
                <p className="subtitle-a">
                  Here are the services rendered by us. Do contact us if you want to find out more.
                </p>
                <div className="line-mf"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div >
                {/* <a href={panel1} data-lightbox="gallery-vmarine"> */}
                <Slide duration="1000000000">
                  <div className="each-Slide">
                    <img src={panel1} width="100%" height="400"/>
                  </div>
                  <div className="each-Slide">
                    <img src={panel2} width="100%" height="400"/>
                  </div>
                  <div className="each-Slide">
                    <img src={panel3} width="100%" height="400"/>
                  </div>
                  <div className="each-Slide">
                    <img src={panel4} width="100%" height="400"/>
                  </div>
                  <div className="each-Slide">
                    <img src={panel5} width="100%" height="400"/>
                  </div>
                </Slide>
                  {/* <div className="work-img">
                    <img src={paneltn} alt="" className="img-fluid" max-height="200px"/>
                  </div> */}
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-8">
                        <h2 className="w-title">Panel Installation</h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                            
                          </span>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                {/* </a> */}
                
                {/* <a
                  href={panel2}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={panel3}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={panel4}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={panel5}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a> */}
              </div>
              
            </div>
            <div className="col-sm-6">
              <div >
                {/* <a href={panel1} data-lightbox="gallery-vmarine"> */}
                <Slide duration="1000000000">
                  <div className="each-Slide">
                    <img src={tile1} width="100%" height="400"/>
                  </div>
                  <div className="each-Slide">
                    <img src={tile2} width="100%" height="400"/>
                  </div>
                  <div className="each-Slide">
                    <img src={tile3} width="100%" height="400"/>
                  </div>
                  <div className="each-Slide">
                    <img src={tile4} width="100%" height="400"/>
                  </div>
                  <div className="each-Slide">
                    <img src={tile5} width="100%" height="400"/>
                  </div>
                </Slide>
                  {/* <div className="work-img">
                    <img src={paneltn} alt="" className="img-fluid" max-height="200px"/>
                  </div> */}
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-8">
                        <h2 className="w-title">Tiling</h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                            
                          </span>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                {/* </a> */}
                
                {/* <a
                  href={panel2}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={panel3}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={panel4}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={panel5}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a> */}
              </div>
              
            </div>
            <div className="col-sm-6">
              <div >
                {/* <a href={panel1} data-lightbox="gallery-vmarine"> */}
                <Slide duration="1000000000">
                  <div className="each-Slide">
                    <img src={pebble1} width="100%" height="400"/>
                  </div>
                  <div className="each-Slide">
                    <img src={pebble2} width="100%" height="400"/>
                  </div>
                  <div className="each-Slide">
                    <img src={pebble3} width="100%" height="400"/>
                  </div>
                  <div className="each-Slide">
                    <img src={pebble4} width="100%" height="400"/>
                  </div>
                </Slide>
                  {/* <div className="work-img">
                    <img src={paneltn} alt="" className="img-fluid" max-height="200px"/>
                  </div> */}
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-8">
                        <h2 className="w-title">Pebble Wash</h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                            
                          </span>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                {/* </a> */}
                
                {/* <a
                  href={panel2}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={panel3}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={panel4}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={panel5}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a> */}
              </div>
              
            </div>
            <div className="col-sm-6">
              <div >
                {/* <a href={panel1} data-lightbox="gallery-vmarine"> */}
                <Slide duration="1000000000">
                  <div className="each-Slide">
                    <img src={skim1} width="100%" height="400"/>
                  </div>
                  <div className="each-Slide">
                    <img src={skim2} width="100%" height="400"/>
                  </div>
                  <div className="each-Slide">
                    <img src={skim3} width="100%" height="400"/>
                  </div>
                </Slide>
                  {/* <div className="work-img">
                    <img src={paneltn} alt="" className="img-fluid" max-height="200px"/>
                  </div> */}
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-8">
                        <h2 className="w-title">Skimcoat</h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                            
                          </span>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                {/* </a> */}
                
                {/* <a
                  href={panel2}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={panel3}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={panel4}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={panel5}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a> */}
              </div>
              
            </div>
            <div className="col-sm-6">
              <div >
                {/* <a href={panel1} data-lightbox="gallery-vmarine"> */}
                <Slide duration="1000000000">
                  <div className="each-Slide">
                    <img src={plaster1} width="100%" height="400"/>
                  </div>
                  <div className="each-Slide">
                    <img src={plaster2} width="100%" height="400"/>
                  </div>
                </Slide>
                  {/* <div className="work-img">
                    <img src={paneltn} alt="" className="img-fluid" max-height="200px"/>
                  </div> */}
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-8">
                        <h2 className="w-title">Plastering</h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                            
                          </span>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                {/* </a> */}
                
                {/* <a
                  href={panel2}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={panel3}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={panel4}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={panel5}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a> */}
              </div>
              
            </div>
            <div className="col-sm-6">
              <div >
                {/* <a href={panel1} data-lightbox="gallery-vmarine"> */}
                <Slide duration="1000000000">
                  <div className="each-Slide">
                    <img src={skirting1} width="100%" height="400"/>
                  </div>
                </Slide>
                  {/* <div className="work-img">
                    <img src={paneltn} alt="" className="img-fluid" max-height="200px"/>
                  </div> */}
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-8">
                        <h2 className="w-title">Skirting</h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                            
                          </span>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                {/* </a> */}
                
                {/* <a
                  href={panel2}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={panel3}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={panel4}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a>
                <a
                  href={panel5}
                  data-lightbox="gallery-vmarine"
                  style={{ display: "none" }}
                >
                  jsx-a11y/anchor-has-content warning
                </a> */}
              </div>
              
            </div>
            
              

          </div>
          
        </div>
      </section>
    );
  }
}

export default Services;
