import React from "react";
import bizsafe from "../img/bizsafe.jpg";
import iso45001 from "../img/iso45001.jpg";
import fence from "../img/services/fence.jpg";
import metal from "../img/services/metal_staircase.jpg";
import railing from "../img/services/railings.jpg";
class About extends React.Component {
  constructor() {
    super();
    this.state = {
      skills: [
        { id: "BizSafe Star Certificate", content: "BizSafe Star Certified", porcentage: "80%", value: "80", image: bizsafe },
        // { id: "ISO45001", content: "ISO45001", porcentage: "75%", value: "75", image: iso45001},
        
        // {
        //   id: "ReactJS_skill",
        //   content: "ReactJS",
        //   porcentage: "80%",
        //   value: "80"
        // },
        // {
        //   id: "Python_skill",
        //   content: "Python",
        //   porcentage: "75%",
        //   value: "75"
        // },
        // {
        //   id: "VanillaJS_skill",
        //   content: "VanillaJS",
        //   porcentage: "85%",
        //   value: "85"
        // },
        // {
        //   id: "Wordpress_skill",
        //   content: "Wordpress",
        //   porcentage: "80%",
        //   value: "80"
        // }
      ],
      about_me: [
        {
          id: "first-p-about",
          content:
            "Founded in 2015, Chin Hoong Construction & Engineering Pte Ltd is an established Singapore-based engineering and construction services company. We specialise in wet works such as tiling, screeding, plastering and installation of precast wall panels in the construction trade. "        },
        // {
        //   id: "second-p-about",
        //   content:
        //     "Lorem ipsum pariatur consectetur laboris occaecat nulla aliqua irure ad deserunt duis. Eiusmod nulla cupidatat labore sint sit aute dolore irure nostrud ut incididunt. Anim laborum reprehenderit labore magna ut dolore quis irure. Labore ea duis deserunt ullamco irure fugiat deserunt ut nisi ea minim proident. Nisi consectetur do non magna duis aliqua minim minim veniam. In occaecat minim qui consequat elit mollit consectetur non id tempor. Amet adipisicing occaecat tempor culpa quis est duis."
        // },
        // {
        //   id: "third-p-about",
        //   content:
        //     "Lorem ipsum pariatur consectetur laboris occaecat nulla aliqua irure ad deserunt duis. Eiusmod nulla cupidatat labore sint sit aute dolore irure nostrud ut incididunt. Anim laborum reprehenderit labore magna ut dolore quis irure. Labore ea duis deserunt ullamco irure fugiat deserunt ut nisi ea minim proident. Nisi consectetur do non magna duis aliqua minim minim veniam. In occaecat minim qui consequat elit mollit consectetur non id tempor. Amet adipisicing occaecat tempor culpa quis est duis."
        // }
      ]
    };
  }

  render() {
    return (
      <section id="about" className="about-mf sect-pt4 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="box-shadow-full">
                <div className="row">
                <div className="col-md-6">
                    <div className="about-me pt-4 pt-md-0">
                      <div className="title-box-2">
                        <h5 className="title-left">About Us</h5>
                      </div>
                      {this.state.about_me.map(content => {
                        return (
                          <p className="lead" key={content.id}>
                            {content.content}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="title-box-2">
                        <h5 className="title-left">Our Credentials</h5>
                      </div>
                    {/* <div className="row">
                      <div
                        className="col-sm-6 col-md-5"
                        style={{ margin: "0 auto" }}
                      >
                        <div
                          className="about-img"
                          style={{ textAlign: "center" }}
                        >
                          <img
                            className="img-fluid rounded b-shadow-a"
                            alt=""
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="skill-mf">
                      
                      {/* <p className="title-s">Skill</p> */}
                      {this.state.skills.map(skill => {
                        return (
                          <React.Fragment key={skill.id}>
                            <div className="work-img">
                            <img src={skill.image} alt="" className="img-custom" width="60%" height="200"/>
                          </div>
                            <span><b>{skill.content}</b></span>{" "}
                            <span className="pull-right">
                            </span>
                            {/* <div className="progress"> */}
                              {/* <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: skill.porcentage }}
                                aria-valuenow={skill.value}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div> */}
                            {/* </div> */}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
